var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "appointment-info",
      on: { dblclick: _vm.handleDoubleClick },
    },
    [
      _c("div", { staticClass: "appointment-info__desc" }, [
        _vm._v("可预约 " + _vm._s(_vm.totalNum) + " 人"),
      ]),
      _c("div", { staticClass: "appointment-info__desc" }, [
        _vm._v("已预约 " + _vm._s(_vm.info.reservations ?? 0) + " 人"),
      ]),
      _c("edit-form", { attrs: { model: _vm.editingObj } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }