<template lang="pug">
data-page
  template(#aside)
    api-based-tree(
      v-model="queryForm.checkOrgId",
      auto-select,
      :load-method="treeLoadMethod"
    )

  template(#header)
    page-header(:actions="pageHeaderActions", @act="handleEntityActions")

  Calendar(
    v-model="calerndarProps.value",
    v-loading="loading",
    :type="calerndarProps.type",
    @on-type-change="(t) => (calerndarProps.type = t)"
  )
    template(#[calerndarProps.type]="{ data }")
      template(v-if="getInfoProps(data.day ?? data.month)")
        appointment-info(
          v-bind="getInfoProps(data.day ?? data.month)",
          :is-year="calerndarProps.type === 'year'",
          @dblclick="handleViewDetail"
        )

  excel-upload(
    v-model="uploadVisiable",
    size="large",
    :org-id="queryForm.checkOrgId",
    @uploaded="() => query()"
  )
</template>

<script>
    import AppointmentInfo from "./components/AppointmentInfo.vue";
    import ExcelUpload from "./components/ExcelUpload.vue";
    export default {
        components: {
            ExcelUpload,
            AppointmentInfo,
        },
        data() {
            return {
                loading: false,
                uploadVisiable: false,
                items: [],
                calerndarProps: {
                    value: new Date(),
                    type: "month",
                },
                queryForm: {
                    dateTime: this.calerndarProps === "month" ? "YYYY-MM" : "YYYY",
                    checkOrgId: "",
                },
            };
        },
        computed: {
            pageHeaderActions() {
                return [
                    {
                        type: "primary",
                        event: "import",
                        label: "批量导入数据",
                        icon: "ios-cloud-upload",
                    },
                ];
            },
        },
        watch: {
            calerndarProps: {
                handler(prop) {
                    Object.assign(this.queryForm, {
                        dateTime: this.$utils.formatDate(
                            prop.value,
                            prop.type === "month" ? "YYYY-MM" : "YYYY"
                        ),
                    });
                },
                immediate: true,
                deep: true,
            },
            queryForm: {
                handler() {
                    this.query();
                },
                deep: true,
            },
        },
        methods: {
            async query() {
                if (this.loading) return;

                this.loading = true;
                try {
                    const res = await this.$api.healthy.tOrderSetting.getInfo(
                        this.queryForm
                    );
                    if (res?.success) {
                        Object.assign(this, {
                            items: res.data,
                        });
                    } else {
                        this.$Message.error(res.message);
                    }
                } catch (err) {
                    console.log(err.message);
                }
                this.loading = false;
            },

            async treeLoadMethod() {
                try {
                    const res = await this.$api.healthy.tCheckOrg.getAllCheckOrg();

                    if (res?.success) {
                        return (res.data ?? []).map((i) => ({
                            title: i.name,
                            value: i.id,
                            _raw: i,
                        }));
                    } else {
                        return [];
                    }
                } catch (err) {
                    return [];
                }
            },

            formatDate(date, format) {
                return this.$utils.formatDate(
                    date,
                    format ?? this.calerndarProps.type === "month"
                        ? "YYYY-MM-DD"
                        : "YYYY-MM"
                );
            },

            getInfoProps(date) {
                const item = this.items.find((i) => {
                    return this.formatDate(date) === this.formatDate(i.orderDate);
                });

                return item
                    ? {
                        info: item,
                    }
                    : void 0;
            },

            handleEntityActions(action) {
                switch (action.event) {
                    case "import":
                        this.uploadVisiable = true;
                        break;
                }
            },

            handleViewDetail() {
                console.log("dbclick");
            },
        },
    };
</script>

<style lang="less" scoped></style>
